<template>
	<div class="leaflet-routes hidden">
    <route-stops v-if="routesStopsPoi" :map="map" :sterfietsroutes="data.sterfietsroutes" :data="routesStopsPoi" />    
  </div>
</template>

<script>

import { mapState } from 'vuex'
import L from 'leaflet'
import RouteStops from "@/components/RouteStops.vue"

export default {
	name: 'LeafletRoutes',
  components: {
		RouteStops
  },  
	props: {
		map: {
			type: [Object],
			custom: false
		},
		sterfietsroutes: {
			type: [Object],
			custom: false
		},
		data: {
			type: [Object],
			custom: false
		},
    debug: {
      type: Boolean,
      custom: false
    }
	},
  computed: {
    ...mapState(['markers', 'stops'])
  },  
	data() {
		return {
			routes: null,
      routesStopsPoi: null,
      poi: null
		}
	},
  created() {
    fetch(process.env.VUE_APP_API_URL+'/api/content/items/Sterfietsroutes', {
		headers: {
			'Content-Type': 'application/json',
			'api-key': process.env.VUE_APP_API_KEY
		}
	})
      .then(res=>res.json())
      .then((res) => {
        let items = []
        res.forEach((marker) => {
          let stops = []
          // let poi = []
          if (marker.stops) {
            marker.stops.forEach((stop) => {
              const item = { 
                "type": "Feature",
                "properties": { "popup": stop.value.popup },
                "geometry": { "type": "Point", "coordinates": [stop.value.location.lng, stop.value.location.lat] }
              }
              stops.push(item)
            })
          }
          // marker.points_of_interests.forEach((point) => {
          //   poi.push(point)
          // })
          items.push({ "stops": stops })         
        })
        this.routesStopsPoi = items
        this.$store.commit('setRoutesStopsPoi', items)
      })
  },
	mounted() {
		this.routes = {}
		for (let key in this.sterfietsroutes.features) {
			let route = this.sterfietsroutes.features[key];
			var leaflet = L.geoJSON(this.sterfietsroutes.features[key], {        
				style: { "color": this.data.sterfietsroutes[route.properties.NR-1].color, "weight": 5, "opacity": 1, "lineCap": "round", "lineJoin": "round" },
				onEachFeature: this.onEachFeature
			}).addTo(this.map);
			if(!this.routes[route.properties.NR]) { 
        this.routes[route.properties.NR] = []
      }
			this.routes[route.properties.NR].push({ "route": route, "leaflet": leaflet });
		}
    this.$store.commit('setRoutes', this.routes)
	},
	methods: {
		onEachFeature(feature, layer) {
      if(this.debug)
        return

      let popupContent = `
				<div class="layer-popup pb-2">
          <h6 class="sterfietsroute text-sm font-bold pt-2">Sterfietsroute</h6>
					<p class="text-base">${feature.properties.LEGENDA_1}</p>
					<button class="bg-white rounded text-black font-bold text-base button-start btn btn-sm px-4 py-2">Bekijk</button>
				</div>
			`;

			layer.bindPopup(popupContent).on("popupopen", (e) => {
				document.querySelector('.button-start').addEventListener('click', event => {
					event.preventDefault()
					this.map.closePopup()
					const extra = this.data.sterfietsroutes[e.target.feature.properties.NR - 1]
					this.map.setView(extra.start, 15);
				});
			});

			layer.on('click', (e) => {
				for (let key in this.routes) {
					this.routes[key].forEach(function (item) {
						item.leaflet.setStyle({ "weight": 5, "opacity": 1 })
					});
				}
        this.$store.commit('setActive', e.target.feature.properties.NR - 1)
        this.$store.commit('setName', this.routes[e.target.feature.properties.NR][0].route.properties.LEGENDA_1)
				this.routes[e.target.feature.properties.NR].forEach(function (item) {
					item.leaflet.setStyle({ "weight": 8, "opacity": 1  })
					item.leaflet.bringToFront()
				});
        this.stops.getLayers().forEach((item) => {
          item.bringToFront()
        });  
			});
		}
	}
}
</script>

<style>
.leaflet-popup p {
  @apply mt-0;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
	background: black;
	color: white;
	border-radius: 4px;
}
.leaflet-popup-close-button {
	color: white;
}
</style>